function datesLabelValidation(className) {
  const label = document.querySelector(`${className} .label`);
  const datePickers = document.querySelectorAll(`${className} .datepicker-input`);
  let counter = 0;
  datePickers.forEach((datePicker) => {
    if (datePicker.value !== '') counter += 1;
  });
  if (counter !== 0) {
    label.classList.add('selected-label');
  } else {
    label.classList.remove('selected-label');
  }
}
window.datesLabelValidation = datesLabelValidation;

function ordinalSuffix(number) {
  const mod = number % 10;
  if (mod === 1 && number !== 11) {
    return 'ˢᵗ';
  }
  if (mod === 2 && number !== 12) {
    return 'ⁿᵈ';
  }
  if (mod === 3 && number !== 13) {
    return 'ʳᵈ';
  }
  return 'ᵗʰ';
}

function addOrdinalSuffix(event) {
  const value = event.target.value;
  if (value !== '') {
    const day = parseInt(value.substr(-2, 2), 10);
    event.target.value = value + ordinalSuffix(day);
  }
  datesLabelValidation('.ctn-omit-times .blocked-dates');
}

document.addEventListener('DOMContentLoaded', () => {
  const datePickers = document.querySelectorAll('.datepicker-input');
  datePickers.forEach((datePicker) => {
    if (!datePicker.classList.contains('skip_suffix')) {
      datePicker.addEventListener('blur', addOrdinalSuffix);
    }
  });
});
