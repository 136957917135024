import $ from 'jquery';
import DTSitesView from '@assets/javascripts/backbone/views/data_tables/dt_sites.js';
import DTEventsView from '@assets/javascripts/backbone/views/data_tables/dt_events.js';
import DTGlobalCommandsView from '@assets/javascripts/backbone/views/data_tables/dt_global_commands.js';
import DTInstructionsView from '@assets/javascripts/backbone/views/data_tables/dt_instructions.js';
import DTControllersView from '@assets/javascripts/backbone/views/data_tables/dt_controllers.js';
import DTSubscriptionsView from '@assets/javascripts/backbone/views/data_tables/dt_subscriptions.js';
import DTSiteAssetsView from '@assets/javascripts/backbone/views/data_tables/dt_site_assets.js';
import DTProposalsView from '@assets/javascripts/backbone/views/data_tables/dt_proposals.js';
import DTPriceListsView from '@assets/javascripts/backbone/views/data_tables/dt_price_lists.js';
import DTJobCatalogItemsView from '@assets/javascripts/backbone/views/data_tables/dt_job_catalog_items.js';
import DTPriceListItemsView from '@assets/javascripts/backbone/views/data_tables/dt_price_list_items.js';
import DTJobCatalogTagsView from '@assets/javascripts/backbone/views/data_tables/dt_job_catalog_tags.js';
import DTAccountSubscriptionsView from '@assets/javascripts/backbone/views/data_tables/accounts/dt_subscriptions.js';
import DTInspectionsView from '@assets/javascripts/backbone/views/data_tables/dt_inspections.js';
import InspectionShowView from '@assets/javascripts/backbone/views/inspections/inspection_show.js';
import InspectionEditView from '@assets/javascripts/backbone/views/inspections/inspection_edit.js';
import InspectionEditZonesView from '@assets/javascripts/backbone/views/inspections/inspection_edit_zones.js';
import InspectionEditNotesView from '@assets/javascripts/backbone/views/inspections/inspection_edit_notes.js';
import InspectionManualRunView from '@assets/javascripts/backbone/views/inspections/inspection_manual_runs.js';
import InspectionEditPhotosView from '@assets/javascripts/backbone/views/inspections/inspection_edit_photos.js';
import InspectionZoneCommentsView from '@assets/javascripts/backbone/views/inspections/inspection_zone_comments.js';
import InspectionsProposalsIndexView from '@assets/javascripts/backbone/views/inspections/inspections_proposals_index.js';
import InspectionsProposalsConfigureView from '@assets/javascripts/backbone/views/inspections/inspections_proposals_configure.js';
import PriceListIndexView from '@assets/javascripts/backbone/views/company/price_list_index.js';
import PriceListItemsIndexView from '@assets/javascripts/backbone/views/company/price_list_items_index.js';
import JobCatalogItemsIndexView from '@assets/javascripts/backbone/views/company/job_catalog_items_index.js';
import JobCatalogTagsIndexView from '@assets/javascripts/backbone/views/company/job_catalog_tags_index.js';
import SiteUsersView from '@assets/javascripts/backbone/views/sites/site_users.js';
import SiteNewControllerView from '@assets/javascripts/backbone/views/sites/site_new_controller.js';
import SiteDeleteView from '@assets/javascripts/backbone/views/sites/site_delete.js';
import SiteShowView from '@assets/javascripts/backbone/views/sites/site_show.js';
import ControllerShowView from '@assets/javascripts/backbone/views/controllers/controller_show_view.js';
import ControllerEditingView from '@assets/javascripts/backbone/views/controllers/controller_editing.js';
import ControllerOptionsView from '@assets/javascripts/backbone/views/controllers/controller_options.js';
import ControllerFilesView from '@assets/javascripts/backbone/views/controllers/controller_files.js';
import ZonePhotosView from '@assets/javascripts/backbone/views/controllers/zone_photos.js';
import SubscriptionView from '@assets/javascripts/backbone/views/subscriptions/subscription_view.js';
import SiteAlertsView from '@assets/javascripts/backbone/views/site_alerts/site_alerts_view.js';
import WaterRestrictionsEditView from '@assets/javascripts/backbone/views/water_restrictions/water_restrictions_edit.js';
import HistoryWidgetView from '@assets/javascripts/backbone/lib/include/history_view.js';
import InspectionProposalsView from '@assets/javascripts/backbone/views/inspections/inspection_proposals.js';
import InspectionSystemIssueView from '@assets/javascripts/backbone/views/inspections/inspection_system_issue.js';
/* eslint-disable no-new */
document.addEventListener('DOMContentLoaded', () => {
  window.historyWidgetView = new HistoryWidgetView();
  // DataTables
  if ($('#sites-index').length > 0) new DTSitesView();
  if ($('#sites-events, #controls-events, #events-index').length > 0) new DTEventsView();
  if ($('#global_commands-index').length > 0) new DTGlobalCommandsView();
  if ($('#sites-instructions, #dashboard-admin-instructions-index, #controls-instructions').length > 0) new DTInstructionsView();
  if ($('#controls-index').length > 0) new DTControllersView();
  if ($('#subscriptions-index').length > 0) new DTSubscriptionsView();
  if ($('#sites-site_assets-index').length > 0) new DTSiteAssetsView();
  if ($('#proposals-index').length > 0) new DTProposalsView();
  if ($('#job_catalog_items-index').length > 0) new DTJobCatalogItemsView();
  if ($('#price_lists-index').length > 0) new DTPriceListsView();
  if ($('#price_list_items-index').length > 0) new DTPriceListItemsView();
  if ($('#job_catalog_tags-index').length > 0) new DTJobCatalogTagsView();
  if ($('#account_subscriptions-index').length > 0) new DTAccountSubscriptionsView();
  if ($('#inspections-index, #sites-inspections, #controls-inspections').length > 0) new DTInspectionsView();
  // Inspections  ye 'ol future brogrammers beware the order of these matters.  proposals need to get called before edit
  if (($('#controller_inspections-edit').length > 0) || ($('#controller_inspections-show').length > 0)) { new InspectionShowView(); }
  if ($('#controller_inspections-edit').length > 0) { new InspectionProposalsView(); }
  if ($('#controller_inspections-edit').length > 0) { new InspectionEditView(); }
  if ($('#controller_inspections-edit').length > 0) { new InspectionEditZonesView(); }
  if ($('#controller_inspections-edit').length > 0) { new InspectionEditNotesView(); }
  if ($('#controller_inspections-edit').length > 0) { new InspectionManualRunView(); }
  if (($('#controller_inspections-edit').length > 0) || ($('#controller_inspections-show').length > 0)) { new InspectionEditPhotosView(); }
  if (($('#controller_inspections-edit').length > 0) || ($('#controller_inspections-show').length > 0)) { new InspectionSystemIssueView(); }
  if (($('#controller_inspections-edit').length > 0) || ($('#controller_inspections-show').length > 0)) { new InspectionZoneCommentsView(); }

  let cshow;
  if ($('#inspections_proposals-index').length > 0) { new InspectionsProposalsIndexView(); }
  if ($('#proposals-configure').length > 0) { new InspectionsProposalsConfigureView(); }

  // Company
  if ($('#price_lists-index').length > 0) { new PriceListIndexView(); }
  if ($('#price_list_items-index').length > 0) { new PriceListItemsIndexView(); }
  if ($('#job_catalog_items-index').length > 0) { new JobCatalogItemsIndexView(); }
  if ($('#job_catalog_tags-index').length > 0) { new JobCatalogTagsIndexView(); }

  // Sites
  if ($('#site_users-index').length > 0) { new SiteUsersView(); }
  if ($('#sites-show').length > 0) { new SiteShowView({ el: $('#sites-show') }); }
  if ($('#sites-new_controller').length > 0) { new SiteNewControllerView({ el: $('#sites-new_controller') }); }
  if ($('#controls-create').length > 0) { new SiteNewControllerView({ el: $('#controls-create') }); }
  if ($('#sites-delete').length > 0) { new SiteDeleteView({ el: $('#sites-delete') }); }

  // Controller
  if ($('#controls-show').length > 0) { cshow = new ControllerShowView(); }
  if ($('#controls-show').length > 0) { new ControllerEditingView({ parent: cshow }); }
  if ($('#controls-edit').length > 0) { new ControllerOptionsView({ el: $('#controls-edit') }); }
  if ($('#controller_images-index').length > 0) { new ControllerFilesView(); }
  if ($('#zone_photos-index').length > 0) { new ZonePhotosView(); }

  // Subscriptions
  if ($('#subscriptions-show').length > 0) { new SubscriptionView(); }

  // Account
  if ($('#sites-alert_settings').length > 0) { new SiteAlertsView(); }

  // Restrictions
  if ($('#dashboard-restrictions-water_restrictions-edit').length > 0) { new WaterRestrictionsEditView(); }
  // if (!Modernizr.input.placeholder) { $('input, textarea').placeholderView(); }

  // Branches
});
