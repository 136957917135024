let laborRateRange;
let laborRateInput;

function updateLaborRate(accountId) {
  window.$.ajax({
    url: `/accounts/${accountId}/update_labor_rate`,
    type: 'PUT',
    dataType: 'script',
    data: { account: { labor_rate: laborRateInput.value.replace('$', '') } },
  });

  document.getElementById('rate_edit').classList.add('tw-hidden');
  document.getElementById('rate_detail').classList.remove('tw-hidden');
  document.querySelector('#rate_detail p').innerText = `$${laborRateInput.value.replace('$', '')}`;
  document.getElementById('rate_edit-button').classList.remove('tw-hidden');
}
window.updateLaborRate = updateLaborRate;

function showUpdateLaborRate() {
  document.getElementById('rate_edit').classList.remove('tw-hidden');
  document.getElementById('rate_detail').classList.add('tw-hidden');
  document.getElementById('rate_edit-button').classList.add('tw-hidden');
}
window.showUpdateLaborRate = showUpdateLaborRate;
function getBackgroundSize(element) {
  const value = element.value.replace('$', '');
  return `${((value - element.min) * 100) / (element.max - element.min)}% 100%`;
}

function toggleLaborRateUpdateButton(action) {
  const updateButton = document.querySelector('#labor-rate-update-btn');
  if (action === 'disable') {
    updateButton.classList.add('tw-btn-disabled');
    updateButton.classList.remove('tw-button-secondary');
  } else {
    updateButton.classList.remove('tw-btn-disabled');
    updateButton.classList.add('tw-button-secondary');
  }
}

function showWarningLabel(value, idSelector) {
  const labelContainer = document.querySelector(`#${idSelector} .warning-label`);
  const label = labelContainer.querySelector('p');
  const parsedValue = parseFloat(value.replace('$', ''), 10);
  if (parsedValue < 10) label.textContent = window.I18n.insights.labor_savings.cards.labor_rate.min_error;
  if (parsedValue > 80) label.textContent = window.I18n.insights.labor_savings.cards.labor_rate.max_error;
  if (!value.match(/^\$?\d+(?:\.\d{1,2})?$/)) label.textContent = window.I18n.insights.labor_savings.cards.labor_rate.not_a_number;
  labelContainer.classList.remove('tw-hidden');
}

function handleLaborRateRangeOnChange(e) {
  e.target.style.backgroundSize = getBackgroundSize(e.target);
  document.getElementById('labor-rate-input').value = `$${parseFloat(e.target.value).toFixed(2)}`;
  document.querySelector('#labor-rate .warning-label').classList.add('tw-hidden');
  toggleLaborRateUpdateButton('enable');
}

function handleLaborRateInputOnChange(e) {
  const range = document.getElementById('labor-rate-range');
  const value = parseFloat(e.target.value.replace('$', ''), 10);
  if (value < 10 || value > 80 || !e.target.value.match(/^\$?\d+(?:\.\d{1,2})?$/)) {
    showWarningLabel(e.target.value, 'labor-rate');
    toggleLaborRateUpdateButton('disable');
    return;
  }
  document.querySelector('#labor-rate .warning-label').classList.add('tw-hidden');
  toggleLaborRateUpdateButton('enable');
  range.style.backgroundSize = getBackgroundSize(e.target);
  // e.target.value = `$${value}`;
  range.value = value;
}

function handleLaborRateInputOnBlur(e) {
  const value = parseFloat(e.target.value);
  if (value >= 10 && value <= 80) e.target.value = `$${value.toFixed(2)}`;
}

function getLaborSavingsInsights() {
  window.$.ajax({
    url: '/insights/labor_savings/labor_savings_insights',
    type: 'GET',
    dataType: 'script',
    success: () => {
      document.querySelectorAll('.btn-dropdown').forEach((button) => button.addEventListener('click', window.dropdownToggle));
      laborRateInput = document.getElementById('labor-rate-input');
      laborRateRange = document.getElementById('labor-rate-range');
      if (laborRateRange) {
        laborRateRange.addEventListener('input', handleLaborRateRangeOnChange);
        laborRateInput.addEventListener('input', handleLaborRateInputOnChange);
        laborRateInput.addEventListener('blur', handleLaborRateInputOnBlur);
        const value = laborRateInput.value.replace('$', '');
        laborRateRange.value = value;
        laborRateRange.style.backgroundSize = getBackgroundSize(laborRateInput);
      }
    },
  });
}

function getYearByYearComparison() {
  window.$.ajax({
    url: '/insights/labor_savings/year_by_year_comparison',
    type: 'GET',
    dataType: 'script',
    success: () => {
      document.querySelectorAll('.btn-dropdown').forEach((button) => button.addEventListener('click', window.dropdownToggle));
    },
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const laborSavingsIndex = document.getElementById('insights-labor_savings-index');

  if (laborSavingsIndex) {
    window.displayLastUpdatedDate('labor_savings-index');
    getLaborSavingsInsights();
    getYearByYearComparison();
    document.getElementById('select_branch')?.addEventListener('change', () => {
      const branchSelector = document.querySelector('#select_branch #branch_id');
      if (branchSelector.value !== branchSelector.dataset.previousValue) {
        branchSelector.dataset.previousValue = branchSelector.value;
        window.updateTimeframeOnLaborSavingsInsightsChart('branch_id');
        window.updatePeriodOnYearByYearChart();
      }
    });
  }
});
