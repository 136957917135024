import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';
import SiteAsseetLatLngInputTemplate from '@assets/javascripts/backbone/templates/sites/site_assets/site_asset_lat_lng_input.ejs';
import SiteAssetInfoWindowTemplate from '@assets/javascripts/backbone/templates/sites/site_assets/site_asset_info_window.ejs';
import SiteAsseetMultiplephotoRowTemplate from '@assets/javascripts/backbone/templates/sites/site_assets/site_asset_multiphoto_row.ejs';
import SiteAssetLoadingIndicatorTemplate from '@assets/javascripts/backbone/templates/sites/site_assets/site_asset_loading_indicator.ejs';
import PhotoThumbnailTemplate from '@assets/javascripts/backbone/templates/inspections/photo_thumbnail.ejs';
import Dropzone from 'dropzone';
import 'ms-dropdown/dist/js/dd.min.js';
import 'ms-dropdown/dist/css/dd.css';
import 'spectrum-colorpicker/spectrum.js';
import 'spectrum-colorpicker/spectrum.css';

const DTSiteAssetsView = Backbone.View.extend({
  el: 'body',
  events: {
    'change .ransack': 'redraw_map_and_update_filter',
    'keydown .dataTables_filter input': Shared.delaySearch,
    'change #filter_presets': Shared.changeFilterPreset,
    'click #create_filter_report': Shared.createFilterReport,
    'click .remove-filter': Shared.removeFilter,
    'click #update_filter_report': Shared.updateFilterReport,
    'click #destroy_filter_report': Shared.destroyFilterReport,
    'click #hide_sidebar': Shared.toggleSidebar,
    'click #sidebar-button': Shared.toggleSidebar,
    'click #dataTable tr.data-row': 'select_row',
    'click #export': 'site_assets_export',
    'click .new-asset-form-btn': 'setup_new_asset_form',
    'click .submit-new-site-asset': 'submit_new_site_asset',
    'click .edit-site-asset': 'edit_site_asset',
    'click .delete-site-asset': 'table_delete_site_asset',
    'click .map-delete-site-asset': 'map_delete_site_asset',
    'click .hide-edit-form': 'hide_edit_form',
    'click .photo': 'view_photo',
    'click #delete': 'delete_photo',
    'click .save-and-close-asset-photo': 'save_and_close_asset_photo',
    'click .toggle-row-highlight': 'toggle_row_highlight',
    'change select[name="site_asset[controller_id]"]': 'change_select_zone_dropdown',
    'change select[name="site_asset[asset_type]"]': 'change_spectrum_colorpicker',
    'change select[name="site_asset[zone_id_picker]"]': 'change_zone_id_input_value',
    'change select#controller_id_eq': 'change_select_zone_filter',
    'fileuploadstart .fileupload input': 'upload_start',
  },
  async initialize() {
    const { Map } = await window.google.maps.importLibrary('maps');

    const map = new Map(document.getElementById('map'), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
      mapId: 'SITES_ASSETS_ID',
    });
    const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary(
      'marker',
    );
    const { DrawingManager } = await window.google.maps.importLibrary('drawing');
    window.PinElement = PinElement;
    window.DrawingManager = DrawingManager;
    window.AdvancedMarkerElement = AdvancedMarkerElement;
    window.map = map;
    window.map.setTilt(0);
    window.view = this;
    window.markers = [];
    window.polys = [];
    window.polygon_count = 0;
    window.drawManager = [];
    window.infos = [];
    this.SiteAsseetLatLngInput = SiteAsseetLatLngInputTemplate;
    this.SiteAssetInfoWindow = SiteAssetInfoWindowTemplate;
    this.SiteAsseetMultiplephotoRow = SiteAsseetMultiplephotoRowTemplate;
    this.SiteAssetLoadingIndicator = SiteAssetLoadingIndicatorTemplate;
    this.PhotoThumbnail = PhotoThumbnailTemplate;

    // ----------------------
    // Intialize icon picker
    // ----------------------
    window.addEventListener('load', () => {
      $('input[name="site_asset[color]"]').spectrum({
        preferredFormat: 'hex3',
        showInput: true,
        allowEmpty: true,
        move(color) {
          $.each(window.polys, (j) => window.polys[j].setOptions({ strokeColor: color.toHexString(), fillColor: color.toHexString() }));
        },
      }).attr('data-spectrum-initialized', true);
    });

    const onDatatableDraw = () => {
      this.hide_all_map_assets();
      this.draw_map();
      Shared.showFilterBoxes();
      Shared.fillBlankCells();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[1, 'desc']],
      bAutoWidth: false,
      searching: false,
      responsive: false,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // Place the controller id inside each <tr>
      fnRowCallback(nRow, aData) {
        if (aData.is_fault_bool) { $(nRow).addClass('alert-danger'); }
        $(nRow).addClass('data-row');
        $(nRow).attr('id', aData.id).attr('n', aData.id);
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/site_assets',
        type: 'POST',
        error: Shared.errorPopup,
        data(params) {
          params.ransack = {};

          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => $(`#${key}`).val(value));

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });
          $.each(Shared.extractRansackFromUrl(), (key, val) => {
            params.ransack[key] = val;
            return $(`#${key}`).val(val);
          });
          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });
        },
      }, // This return is very important for some reason

      columns: [
        { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
        {
          data: 'name', name: 'name', visible: window.COLUMNS.name.visible, orderable: true,
        },
        { data: 'asset_group', name: 'asset_group', visible: window.COLUMNS.group.visible },
        {
          data: 'controller', name: 'controller', visible: window.COLUMNS.group.visible, orderable: false,
        },
        {
          data: 'zone', name: 'zone', visible: window.COLUMNS.group.visible, orderable: false,
        },
        {
          data: 'asset_type_as_string', name: 'asset_type_as_string', visible: window.COLUMNS.asset_type_as_string.visible, orderable: false,
        },
        { data: 'serial', name: 'serial', visible: window.COLUMNS.serial.visible },
        { data: 'display_color_or_icon', name: 'color', visible: window.COLUMNS.color.visible },
        { data: 'notes', name: 'notes', visible: window.COLUMNS.notes.visible },
        {
          data: 'shortcuts', name: 'shortcuts', visible: window.COLUMNS.shortcuts.visible, orderable: false,
        },
      ],
    });

    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    $('.dataTables_filter input').off();
  },
  upload_start() {
    // Add a loading indicator here
  },

  // ----------------------
  // Redraw Map and Update Filter
  //
  // Hides map assets before updating filter to prevent
  // redrawing polygons on top of each other
  // ----------------------
  redraw_map_and_update_filter() {
    this.hide_all_map_assets();
    Shared.updateRansackFilter();
  },

  // ----------------------
  // Draw Map
  // This will draw the map with all the markers from the
  // current dataTable data() being displayed
  // ----------------------
  draw_map() {
    const self = this;

    window.map.has_coordinates = false;
    if (window.cluster) { window.cluster.clearMarkers(); }
    window.markers.forEach((marker) => marker.setMap(null));
    window.markers.length = 0;
    window.infos.length = 0;
    let markerCount = 0;
    const bounds = new window.google.maps.LatLngBounds();

    let n = 0;
    window.table.rows().data().each((r) => {
      if (r.site) {
        r.location_point_containers.forEach((lpc) => {
          console.log(lpc);
          if (lpc.location_points.length > 1) {
            let p; let polyColor;
            const polyCoords = [];
            const locationIds = [];
            lpc.location_points.forEach((i) => {
              if (i.latitude && i.longitude) {
                locationIds.push(i.id);
                polyCoords.push({ lat: parseFloat(i.latitude), lng: parseFloat(i.longitude) });
                const latLng = new window.google.maps.LatLng({ lat: parseFloat(i.latitude), lng: parseFloat(i.longitude) });
                bounds.extend(latLng);
                window.map.has_coordinates = true;
              }
            });

            const infoWindow = new window.google.maps.InfoWindow({ id: r.id, n });
            self.set_info_window_callback(infoWindow);

            if (r.color) {
              polyColor = `#${r.color}`;
            } else {
              polyColor = '#FF0000';
            }

            if ((r.asset_type === 15) || (r.asset_type === 16)) {
              p = new window.google.maps.Polyline({
                path: polyCoords, lp_ids: locationIds, id: r.id, n: r.id, geodesic: true, s_id: r.site.id, strokeColor: polyColor, strokeOpacity: 1.0, strokeWeight: 2,
              });
            } else if (r.asset_type === 18) {
              p = new window.google.maps.Polygon({
                paths: polyCoords, lp_ids: locationIds, id: r.id, n: r.id, s_id: r.site.id, fillColor: polyColor,
              });
            } else {
              return;
            }
            console.log(p);
            console.log(window.polys);

            window.polys.push(p);
            p.setMap(window.map);
            window.infos.push(infoWindow);

            p.addListener('click', (event) => {
              $.each(window.infos, (j) => window.infos[j].close());
              const tr = $(`#sites tr[id=${r.id}]`);
              tr.addClass('selected');

              // Replace the info window's content and position.
              infoWindow.setContent(window.view.SiteAssetInfoWindow({ site_asset: r }));
              infoWindow.setPosition(event.latLng);

              infoWindow.open(window.map);
            });
          } else if (lpc.location_points.length === 1) {
            let iconPath;
            const i = new window.google.maps.InfoWindow({ id: r.id, n, content: window.view.SiteAssetInfoWindow({ site_asset: r }) });

            // Keep this temporarily for backwards compatibility, but else statement is obsolete now that point assets always have icons
            if (r.icon_path) {
              iconPath = r.icon_path;
            } else {
              const gmapsIconRoot = '//maps.google.com/mapfiles/ms/icons/';
              if (r.color && (r.color[0] !== '#')) {
                iconPath = `${gmapsIconRoot + self.convert_google_hex_to_word(r.color)}-dot.png`;
              }
            }

            const glyphImg = document.createElement('img');
            glyphImg.src = iconPath;
            const glyphPinElement = new window.PinElement({
              glyphColor: 'transparent',
              background: 'transparent',
              borderColor: 'transparent',
              glyph: glyphImg,
            });

            const lp = lpc.location_points[0];
            if (lp.latitude && lp.longitude) {
              const m = new window.google.maps.marker.AdvancedMarkerElement({
                title: `${r.id}-${lp.id}`,
                content: glyphPinElement.element,
                position: new window.google.maps.LatLng(parseFloat(lp.latitude), parseFloat(lp.longitude)),
              });
              self.set_info_window_callback(i);

              window.markers.push(m);
              m.setMap(window.map);
              window.infos.push(i);
              bounds.extend(m.position);
              window.map.has_coordinates = true;

              const marker = window.markers.find((w) => parseInt(w.title.split('-')[0], 10) === parseInt(r.id, 10));
              const info = window.infos.find((inf) => inf.id === parseInt(r.id, 10));

              window.google.maps.event.addListener(window.markers[markerCount], 'click', () => {
                $.each(window.infos, (j) => window.infos[j].close());
                info.open(window.map, marker);
                $('#sites tr').removeClass('selected');
                window.map.setCenter(this.position);

                const tr = $(`#sites tr[id=${this.id}]`);
                tr.addClass('selected');
              });
              markerCount += 1;
            }
          }
          n += 1;
        });
      }
    });

    // if no assets exist for the site, center the map on the site's lat/lng
    // coordinates, or if those are nil, center the map on the US
    if (n === 0) {
      const sLat = $('#default-site-lat-lng').data('default-latitude');
      const sLng = $('#default-site-lat-lng').data('default-longitude');
      if (sLat && sLng) {
        const latLng = new window.google.maps.LatLng({ lat: sLat, lng: sLng });
        bounds.extend(latLng);
        window.map.has_coordinates = true;
      } else {
        window.map.setCenter({ lat: 39.8282, lng: -98.5795 });
        window.map.setZoom(4);
      }
    }

    if (window.map.has_coordinates) {
      window.map.fitBounds(bounds);
    }
  },
  // ----------------------
  // SET INFO WINDOW CALLBACK
  // creates callback function to reset map on close
  // ----------------------
  set_info_window_callback(infoWindow) {
    window.google.maps.event.addListener(infoWindow, 'closeclick', () => {
      $('#dataTable tr').removeClass('selected');
      $('.site_asset_container').addClass('hidden');
      $('.map-row-size').removeClass('col-md-9').addClass('col-md-12');
      $.each(window.table.rows(), (j) => window.table.row(j).child(0).hide());
      this.hide_all_map_assets();
      this.draw_map();
    });
  },

  // ----------------------
  // SHOW NEW ASSET FORM
  // called from new asset button to shrink map and show new asset form
  // ----------------------
  setup_new_asset_form() {
    const self = this;

    $('.map-row-size').removeClass('col-md-12').addClass('col-md-9');
    $('.site_asset_container').addClass('hidden');
    $('.site_asset_container.new_asset_form').removeClass('hidden');

    this.hide_all_map_assets();
    const drawManager = this.draw_manager_init();

    window.drawManager.push(drawManager);
    window.google.maps.event.addListener(drawManager, 'overlaycomplete', (event) => {
      let i;
      if ((event.type === 'polygon') || (event.type === 'polyline')) {
        $('form.new_site_asset').find('#site_asset_square_footage')[0].value = parseFloat($('form.new_site_asset').find('#site_asset_square_footage')[0].value) + window.google.maps.geometry.spherical.computeArea(event.overlay.getPath().getArray());
        const vertices = event.overlay.getPath();
        i = 0;
        while (i < vertices.length) {
          const vertex = vertices.getAt(i);
          $('.location_points_input_container.new_asset').append(self.SiteAsseetLatLngInput({
            lat: vertex.lat(), lng: vertex.lng(), i, polygon_count: window.polygon_count,
          }));
          i += 1;
        }
        window.polygon_count += 1;
      } else if (event.type === 'marker') {
        const marker = event.overlay;
        $('.location_points_input_container.new_asset').append(self.SiteAsseetLatLngInput({
          lat: marker.position.lat(), lng: marker.position.lng(), i: 0, polygon_count: 0,
        }));
      }
    });
    drawManager.setMap(window.map);
  },

  // ----------------------
  // DRAW MANAGER INIT
  // Initializer for google maps draw manager for creating new site assets
  // ----------------------
  draw_manager_init() {
    /* eslint-disable-next-line no-new */
    const drawingManager = new window.DrawingManager({
      drawingMode: 'marker',
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.BSIC,
        drawingModes: [
          'marker',
          'polygon',
          'polyline',
        ],
      },
      markerOptions: { icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png' },
    });
    return drawingManager;
  },

  // ----------------------
  // UPDATE POLY LAT LNG
  // Ajax updater for latitude/longitude of gmaps polygons and polylines
  // ----------------------
  update_poly_lat_lng(poly) {
    const vertices = poly.getPath();
    if (vertices.length > 1) {
      const params = { site_asset: { location_points_attributes: [] } };

      let i = 0;
      while (i < vertices.length) {
        const vertex = vertices.getAt(i);
        params.site_asset.location_points_attributes.push({
          id: poly.lp_ids[i],
          latitude: vertex.lat(),
          longitude: vertex.lng(),
        });
        i += 1;
      }

      $.ajax(`/api/v2/site_assets/${poly.id}`, {
        type: 'PUT',
        dataType: 'json',
        data: params,
        success() {
          console.log('success');
        },
      });
    }
  },

  // ----------------------
  // UPDATE MARKER LAT LNG
  // Ajax updater for latitude/longitude of gmaps markers
  // ----------------------
  update_marker_lat_lng(marker) {
    const lpId = marker.title.split('-')[1];
    if (lpId) {
      const params = { site_asset: { location_points_attributes: [{ id: lpId, latitude: marker.position.lat, longitude: marker.position.lng }] } };
      $.ajax(`/api/v2/site_assets/${marker.title.split('-')[0]}`, {
        type: 'PUT',
        data: params,
        success() {
          console.log('success');
        },
      });
    }
  },

  // ----------------------
  // HIDE ALL MARKERS
  // Hide all polys and markers on map
  // ----------------------
  hide_all_map_assets() {
    if (window.cluster) {
      window.cluster.clearMarkers();
    }
    $.each(window.drawManager, (j) => window.drawManager[j].setMap(null));
    $.each(window.markers, (j) => window.markers[j].setMap(null));
    $.each(window.polys, (j) => window.polys[j].setMap(null));
  },

  // ----------------------
  // SUBMIT NEW SITE ASSET
  // add editable rows after table is created
  // ----------------------
  submit_new_site_asset(e) {
    e.preventDefault();
    // Set color
    const myTarget = $(e.target).closest('form').find('#site_asset_color');
    if (myTarget[0].dataset.spectrumInitialized && myTarget.spectrum('get')) {
      const spectrumColorHexcode = myTarget.spectrum('get').toHex();
      myTarget.val(spectrumColorHexcode);
    }

    return e.target.closest('form').submit();
  },

  // ----------------------
  // DELETE SITE ASSET FROM TABLE
  // called from delete button on site asset row
  // to delete site asset then reload page
  // ----------------------
  table_delete_site_asset(e) {
    e.preventDefault();

    const $target = $(e.target);
    /* eslint-disable-next-line no-restricted-globals */
    const confirmDelete = confirm('Are you sure you wish to delete this site asset?');

    if (confirmDelete) {
      this.hide_edit_form(e);
      const assetId = $target.attr('data-id');

      $.ajax(`/api/v2/site_assets/${assetId}`, {
        type: 'DELETE',
        dataType: 'json',
        success() {
          window.location.reload();
        },
      });
    }
  },

  // ----------------------
  // DELETE SITE ASSET FROM MAP
  // called from delete button on map infobox
  // to delete site asset then reload page
  // ----------------------
  map_delete_site_asset(e) {
    e.preventDefault();
    const $target = $(e.target);
    /* eslint-disable-next-line no-restricted-globals */
    const confirmDelete = confirm('Are you sure you wish to delete this site asset?');

    if (confirmDelete) {
      const assetId = $target.attr('data-id');

      $.ajax(`/api/v2/site_assets/${assetId}`, {
        type: 'DELETE',
        dataType: 'json',
        success() {
          window.location.reload();
        },
      });
    }
  },
  // ----------------------
  // EDIT SITE ASSET
  // called from edit site asset form to submit edits
  // updates lat/lng hidden inputs then submits entire form
  // ----------------------
  edit_site_asset(e) {
    const self = this;
    e.preventDefault();
    let squareFootage = 0;

    // Set color
    const myTarget = $(e.target).closest('form').find('#site_asset_color');
    if (myTarget[0].dataset.spectrumInitialized && myTarget.spectrum('get')) {
      const spectrumColorHexcode = myTarget.spectrum('get').toHex();
      myTarget.val(spectrumColorHexcode);
    }

    // Set square footage
    if ($(e.target).attr('poly') === 'true') {
      const poly = window.polys.filter((i) => i.id === parseInt($(e.target).attr('id'), 10));
      poly.forEach((p) => {
        squareFootage += window.google.maps.geometry.spherical.computeArea(p.getPath().getArray());
        self.update_poly_lat_lng(p);
      });
      $(e.target).closest('form').find('#site_asset_square_footage')[0].value = squareFootage;
    } else {
      const marker = window.markers.find((w) => parseInt(w.title.split('-')[0], 10) === parseInt($(e.target).attr('id'), 10));
      self.update_marker_lat_lng(marker);
    }

    e.target.closest('form').submit();
  },

  hide_edit_form(e) {
    const self = this;
    e.preventDefault();

    const tr = $(e.target).closest('tr');

    if (tr.hasClass('selected')) {
      $('#dataTable tr').removeClass('selected');
    }

    $('.map-row-size').removeClass('col-md-9').addClass('col-md-12');
    $('.site_asset_container').addClass('hidden');
    $.each(window.table.rows(), (j) => window.table.row(j).child(0).hide());
    self.hide_all_map_assets();
    return self.draw_map();
  },
  initialzeSpectrumOnInput(input) {
    if (!input.attr('data-spectrum-initialized')) {
      input.spectrum({
        preferredFormat: 'hex3',
        showInput: true,
        allowEmpty: true,
        move(color) {
          $.each(window.polys, (j) => window.polys[j].setOptions({ strokeColor: color.toHexString(), fillColor: color.toHexString() }));
        },
      }).attr('data-spectrum-initialized', true);
    }
  },

  // ----------------------
  // CHANGE SPECTRUM COLORPICKER
  // called asset type is called to change between palette
  // and hexcode settings
  // ----------------------
  change_spectrum_colorpicker(event) {
    const assetType = $(event.target).val();

    if ((assetType === '15') || (assetType === '16') || (assetType === '18')) {
      this.initialzeSpectrumOnInput($('input[name="site_asset[color]"]', $(event.target).parents('form')));
      $('.color-picker-container', $(event.target).parents('form')).removeClass('hidden');
      $('.icon-picker-container', $(event.target).parents('form')).addClass('hidden');
    } else {
      $('.color-picker-container', $(event.target).parents('form')).addClass('hidden');
      $('.icon-picker-container', $(event.target).parents('form')).removeClass('hidden');
    }
  },

  convert_google_hex_to_word(hexcode) {
    if (hexcode === '6991fd') {
      return 'blue';
    }
    if (hexcode === 'fd7567') {
      return 'red';
    }
    if (hexcode === '8e67fd') {
      return 'purple';
    }
    if (hexcode === 'fdf569') {
      return 'yellow';
    }
    if (hexcode === 'fd9827') {
      return 'orange';
    }
    return 'green';
  },

  // ----------------------
  // CHANGE SELECT ZONE DROPDOWN
  // called when change controller dropdown changes to hide/show
  // appropriate zone dropdowns
  // ----------------------
  change_select_zone_dropdown(event) {
    const controllerId = $('option:selected', event.target).val();
    if (controllerId) {
      $('.zone-dropdown').removeClass('hidden');
      $("select[name='site_asset[zone_id_picker]']", $(event.target).parents('form')).addClass('hidden');

      // Populate new zone select
      return $.ajax(`/api/v2/controllers/${controllerId}?embed_zones=true`, {
        type: 'GET',
        success(data) {
          $(`select[data_controller_id='${controllerId}']`, $(event.target).parents('form')).empty();
          $(`select[data_controller_id='${controllerId}']`, $(event.target).parents('form')).append('<option></option>');
          $.each(data.zones, (index, zone) => $(`select[data_controller_id='${controllerId}']`, $(event.target).parents('form')).append(`<option value='${zone.id}'>Zone ${zone.number}</option>`));
          return $(`select[data_controller_id='${controllerId}']`, $(event.target).parents('form')).removeClass('hidden');
        },
      });
    }
    return $('.zone-dropdown').addClass('hidden');
  },

  // ----------------------
  // CHANGE SELECT ZONE FILTER
  // called when change controller dropdown changes to hide/show
  // appropriate zone dropdowns
  // ----------------------
  change_select_zone_filter(event) {
    const controllerId = $('option:selected', event.target).val();
    if (controllerId) {
      $('.zone-filter').removeClass('hidden');
      $('select.zone_filter_select').addClass('hidden');
      return $(`select.zone_filter_select[data_controller_id='${controllerId}']`).removeClass('hidden');
    }
    return $('.zone-filter').addClass('hidden');
  },

  // ----------------------
  // CHANGE SELECT ZONE DROPDOWN
  // called when change controller dropdown changes to hide/show
  // appropriate zone dropdowns
  // ----------------------
  change_zone_id_input_value(event) {
    const zoneId = $('option:selected', event.target).val();
    return $(event.target).parents('form').find('#site_asset_zone_id').val(zoneId);
  },

  toggle_row_highlight(event) {
    let id;
    if ($(event.target).data('id')) {
      id = $(event.target).data('id');
    } else {
      id = $(event.target).parents('.toggle-row-highlight').data('id');
    }
    return this.setup_selected_row($(`tr[id=${id}]`));
  },

  initialize_file_upload(assetId) {
    const self = this;

    $(`.dz_site_asset_photo_box_${assetId}`).dropzone({
      paramName: 'file',
      acceptedFiles: 'image/jpeg, image/png, image/gif',
      success(file, result) {
        const dzObject = Dropzone.forElement(`.dz_site_asset_photo_box_${assetId}`);
        const gallery = $(`.site_asset_photos[site_asset_id="${assetId}"]`);
        gallery.removeClass('hide');
        gallery.append(self.PhotoThumbnail({ result }));
        if ((dzObject.getQueuedFiles().length === 0) && (dzObject.getUploadingFiles().length === 0)) {
          dzObject.removeAllFiles(true);
        }
      },
      error() {
        Dropzone.forElement(`.dz_site_asset_photo_box_${assetId}`).removeAllFiles(true);
        alert('Sorry there was a problem with your upload. Please try again and make sure your image has the extension .jpeg, .png, or .gif.');
      },
    });

    $(`.dz_site_asset_photo_box_${assetId}`).on('complete', () => {
      if ((this.getQueuedFiles().length === 0) && (this.getUploadingFiles().length === 0)) {
        const context = this;
        context.removeAllFiles();
      }
    });
  },

  // ----------------------
  // SELECT ROW
  // Handle tablerow click event
  // ----------------------
  select_row(event) {
    let tr;
    if (event.target) {
      tr = $(event.target).closest('tr');
    } else {
      tr = event;
    }

    this.setup_selected_row(tr);
  },

  setup_selected_row(tr) {
    let row;
    const self = this;

    for (let index = 0, end = window.table.rows()[0].length + 1, asc = end >= 0; asc ? index <= end : index >= end; asc ? index += 1 : index -= 1) {
      if (window.table.row(index).child()) {
        window.table.row(index).child().hide();
      }
    }

    if (tr.hasClass('selected')) {
      $('#dataTable tr').removeClass('selected');
      $('.site_asset_container').addClass('hidden');
      $('.map-row-size').removeClass('col-md-9').addClass('col-md-12');
      row = window.table.row(tr);
      row.child().hide();
      self.hide_all_map_assets();
      self.draw_map();
    } else {
      row = window.table.row(tr);
      const loadingChildRow = row.child(
        window.view.SiteAssetLoadingIndicator(),
        'odd',
      ).show();

      self.initialzeSpectrumOnInput($('input[name="site_asset[color]"]', $(`.site_asset_container[data-asset-id=${tr.attr('id')}]`)));

      $.ajax(`/api/v2/site_assets/${tr.attr('n')}`, {
        type: 'GET',
        success(data) {
          loadingChildRow.hide();
          row.child(
            window.view.SiteAsseetMultiplephotoRow({ asset_id: data.result.site_asset.id, photos: data.result.site_asset.philes }),
            'odd',
          ).show();

          const controllerId = data.result.site_asset.controller_id;
          if (controllerId) {
          // Populate new zone select
            $.ajax(`/api/v2/controllers/${controllerId}?embed_zones=true`, {
              type: 'GET',
              success(result) {
                $(`select[data_controller_id='${controllerId}']`, $(`.site_asset_container[data-asset-id=${tr.attr('id')}]`)).empty();
                $(`select[data_controller_id='${controllerId}']`, $(`.site_asset_container[data-asset-id=${tr.attr('id')}]`)).append('<option></option>');
                $.each(result.zones, (index, zone) => {
                  if (data.result.site_asset.zone_id === zone.id) {
                    $(`select[data_controller_id='${controllerId}']`, $(`.site_asset_container[data-asset-id=${tr.attr('id')}]`)).append(`<option value='${zone.id}' selected>Zone ${zone.number}</option>`);
                  } else {
                    $(`select[data_controller_id='${controllerId}']`, $(`.site_asset_container[data-asset-id=${tr.attr('id')}]`)).append(`<option value='${zone.id}'>Zone ${zone.number}</option>`);
                  }
                });
                $(`select[data_controller_id='${controllerId}']`, $(`.site_asset_container[data-asset-id=${tr.attr('id')}]`)).removeClass('hidden');
              },
            });
          }
          self.initialize_file_upload(tr.attr('n'));
        },
      });

      $('#dataTable tr').removeClass('selected');
      tr.addClass('selected');

      const assetId = parseInt(tr.attr('id'), 10);
      $('.map-row-size').removeClass('col-md-12').addClass('col-md-9');
      $('.site_asset_container').addClass('hidden');
      $(`.site_asset_container[data-asset-id=${assetId}]`).removeClass('hidden');

      const marker = window.markers.find((w) => parseInt(w.title.split('-')[0], 10) === parseInt(tr.attr('id'), 10));
      const info = window.infos.find((i) => i.id === parseInt(tr.attr('id'), 10));
      const poly = window.polys.find((i) => i.id === parseInt(tr.attr('id'), 10));
      self.hide_all_map_assets();

      if (marker) {
        marker.setMap(window.map);
        window.map.setCenter(marker.position);
        window.map.setZoom(25);
        marker.gmpDraggable = true;

        info.open(window.map, marker);
      } else if (poly) {
        const bounds = new window.google.maps.LatLngBounds();
        poly.forEach((p) => {
          p.setMap(window.map);

          const vertices = p.getPath();
          p.set('editable', true);

          let i = 0;
          while (i < vertices.length) {
            bounds.extend(vertices.getAt(i));
            i += 1;
          }
        });

        window.map.setCenter(bounds.getCenter());
      }
    }
  },

  // ----------------------
  // VIEW PHOTO
  // shows the photo in the modal
  // ----------------------
  view_photo(e) {
    e.preventDefault();
    const t = $(e.target);
    const id = t.attr('data-id');
    const medium = t.attr('data-medium');
    const original = t.attr('data-original');
    const caption = t.attr('data-caption');
    $('.modal-photo-body').html(`<center><img src="${medium}"/></center>`);
    $('#asset-photo-caption').val(caption);
    $('#delete').attr('data-phile-id', id);
    $('#dismiss-modal').attr('data-phile-id', id);
    $('#original').attr('href', original);
    return $('.inspection-photo-modal').modal('show');
  },

  // ----------------------
  // DELETE PHOTO
  // called after hitting delete button within photo modal to
  // delete photo contained in the modal
  // ----------------------
  delete_photo(e) {
    /* eslint-disable-next-line no-restricted-globals */
    if (confirm('Are you sure?')) {
      $(`.photo[data-id='${$(e.target).attr('data-phile-id')}']`).remove();
      $('.modal').modal('hide');
      $.ajax(
        `/api/v2/philes/${$(e.target).attr('data-phile-id')}`,
        { type: 'DELETE' },
      );
    }
  },

  // ----------------------
  // SITE ASSETS EXPORT
  // called on export button click to convert all site asset information
  // into a csv report
  // ----------------------
  site_assets_export(e) {
    let csv = '';

    // headers
    let j = 0;
    const columnLength = Object.keys(window.COLUMNS).length;
    while (j < columnLength) {
      if (window.table.column(j).visible()) {
        if ($(window.table.column(j).header()).text() !== 'Actions') {
          if ($(window.table.column(j).header()).text() === 'ID') {
            csv += $(window.table.column(j).header()).text().toLowerCase();
            csv += ',';
          } else {
            csv += $(window.table.column(j).header()).text();
            csv += ',';
          }
        }
      }
      j += 1;
    }
    csv += '%0A';

    // actual data
    $.each(window.table.rows().data(), (index) => {
      let i = 0;
      while (i < columnLength) {
        if (window.table.row(index).column(i).visible()) {
          let value = window.table.column(i).data()[index];
          if ((typeof (value) !== 'string') || !value.includes('btn-default')) {
            if (value && (value.toString().charAt(0) === '<')) {
              value = $(value).attr('export');
            } else if ((value && (value.toString() === '&nbsp;')) || (value === null)) {
              value = '';
            }
            csv += `"${escape(value)}",`;
          }
        }
        i += 1;
      }
      csv += '%0A';
    });
    $(e.target).attr('href', `data:text/csv;charset=utf8,${csv}`).attr('download', 'report.csv');
  },

  save_and_close_asset_photo(e) {
    e.preventDefault();
    const t = $(e.target);
    const phileId = t.attr('data-phile-id');
    const caption = $('#asset-photo-caption').val();

    $.ajax({
      url: `/api/v2/philes/${phileId}`,
      type: 'PUT',
      dataType: 'json',
      data: ({ description: caption }),
      success(response) {
        $(`.asset-photo[data-id='${t.attr('data-phile-id')}']`).attr('data-caption', response.description);
      },
      error() {
        alert('Sorry something went wrong.');
      },

    });
  },
});
export default DTSiteAssetsView;
